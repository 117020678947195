
const checkboxAjax = (check) => {
    $.ajax({
        type: 'post',
        dataType: 'json',
        url: wineFilter_globals.ajax_url,
        data: {
            id: check.getAttribute('value'),
            action: 'wineFilter_cart',
            _ajax_nonce: wineFilter_globals.nonce
        },
        success: function (response) {
            console.log(response)
            if (response.checked) {
                check.checked = true
            } else {
                check.checked = false
            }
        }
    }
    )
}
const addcheckbox = () => {
    let checkboxes = document.querySelectorAll('.cardcheckbox');
    checkboxes.forEach(check => {
        if (check.hasAttribute('data-listener')) {
            check.removeEventListener('click', (e) => {
                e.preventDefault()
                checkboxAjax(check)
                check.removeAttribute('data-listener')
            })
        } else {
            check.addEventListener('click', (e) => {
                e.preventDefault()
                checkboxAjax(check)
                check.setAttribute('data-listener', 'true')
            })
        }
    })
}
if (document.querySelector('.horeca .horeca__listing')) {
    addcheckbox()

    let tabelCreator = (i, p, c, k, t) => {
        let ta = " <table> <tbody>"
        let vars = [i, p, c, k, t];
        let names = ['Intensywność', 'Moc', 'Słodycz', 'Kwaśność', 'Tanina']
        let iterations = 0
        vars.forEach(v => {
            let tr = '<tr data-aos="fade-up">'
            for (let j = 0; j <= 5; j++) {
                if (j == 0) {
                    tr = tr + '<td>' + names[iterations] + '</td>'
                } else {

                    if (j <= v) {
                        tr = tr + '<td><span class="dot full"></span></td>'
                    } else {
                        tr = tr + '<td><span class="dot"></span></td>'
                    }
                }
            }
            tr = tr + '</tr>'
            ta = ta + tr
            iterations++;
        });
        ta = ta + "</tbody></table>"
        return ta
    }
    const changeWines = (country) => {
        $.ajax({
            type: 'post',
            dataType: 'json',
            url: wineFilter_globals.ajax_url,
            data: {
                country: country,
                action: 'wineFilter_horeca',
                _ajax_nonce: wineFilter_globals.nonce
            },
            success: function (response) {
                if ('success' == response.type) {
                    const box = document.querySelector('.horeca .horeca__listing')
                    const posts = response.data;
                    if (posts == '') {
                        box.innerHTML = '<h2> Nie znaleziono takich win </h2>'
                    } else {
                        let wines = '';
                        if (response.b2b) {
                            posts[0].forEach(post => {

                                var checked = ''
                                if (post['checked']) {
                                    checked = 'checked'
                                }
                                wines = wines + ' <div class="horeca__wine" data-aos="fade-up"><h2>' + post['title'] + '</h2><img src="' + post['img'] + '" alt=""><span>' + post['kind'] + ' ' + post['taste'] + '</span><div class="horeca__wine__details"><div><img src="http://127.0.0.1:3020/wp-content/themes/rynekwin/img/horecacapacity.svg" alt="">' + post['capacity'] + '</div><div><img src="http://127.0.0.1:3020/wp-content/themes/rynekwin/img/horecapercentage.svg" alt="">' + post['percentage'] + '</div><div><img src="http://127.0.0.1:3020/wp-content/themes/rynekwin/img/horecatemp.svg" alt="">' + post['temp'] + '</div></div>' + tabelCreator(post['i'], post['p'], post['c'], post['k'], post['t']) + '<p>' + post['excerpt'] + '</p><label for="wine' + post['id'] + '">B2B</label> <input type="checkbox" value="' + post['id'] + '"' + checked + ' data-listener="true" class="cardcheckbox" id="wine' + post['id'] + '"></div>'
                            })
                        } else {
                            posts[0].forEach(post => {
                                wines = wines + ' <div class="horeca__wine" data-aos="fade-up"><h2>' + post['title'] + '</h2><img src="' + post['img'] + '" alt=""><span>' + post['kind'] + ' ' + post['taste'] + '</span><div class="horeca__wine__details"><div><img src="http://127.0.0.1:3020/wp-content/themes/rynekwin/img/horecacapacity.svg" alt="">' + post['capacity'] + '</div><div><img src="http://127.0.0.1:3020/wp-content/themes/rynekwin/img/horecapercentage.svg" alt="">' + post['percentage'] + '</div><div><img src="http://127.0.0.1:3020/wp-content/themes/rynekwin/img/horecatemp.svg" alt="">' + post['temp'] + '</div></div>' + tabelCreator(post['i'], post['p'], post['c'], post['k'], post['t']) + '<p>' + post['excerpt'] + '</p></div>'
                            })
                        }
                        box.innerHTML = wines;
                        if (response.b2b) {
                            addcheckbox()
                        }
                    }
                    document.querySelectorAll('.horeca__choose__button').forEach(btn => {
                        if (btn.classList.contains('horeca__choose__button--active')) {
                            btn.classList.remove('horeca__choose__button--active')
                        } if (btn.getAttribute('data-country') == country) {
                            btn.classList.add('horeca__choose__button--active')
                        }
                    })
                }
            }
        })
    }

    let btns = document.querySelectorAll('.horeca__choose__button')
    btns.forEach(choose => {
        choose.addEventListener('click', (e) => {
            let country = choose.getAttribute('data-country');

            changeWines(country);
        })
    });
}