
const removeHref = (id) => {
    $.ajax({
        type: 'post',
        dataType: 'json',
        url: wineFilter_globals.ajax_url,
        data: {
            id: id,
            action: 'wineFilter_cartRemove',
            _ajax_nonce: wineFilter_globals.nonce
        },
        success: function (response) {
            if(document.querySelector('.popup')){

                let popup = document.querySelector('.popup')
                let tbody = popup.querySelector('table tbody');
                tbody.innerHTML = response.cart
                popup.classList.add('active')
                if(document.querySelector('.cardcheckbox[value="'+id+'"]')){
                    document.querySelector('.cardcheckbox[value="'+id+'"]').checked = false
                }
                addhrefs()
            }else{
                let tbody = document.querySelector('table tbody');
                tbody.innerHTML = response.cart
                addhrefs()
            }
        }
    }
    )
}
const addhrefs = () => {
    let hrefs = document.querySelectorAll('.wine__remove')
    hrefs.forEach(href => {
        href.removeEventListener('click', (e) => {
            e.preventDefault()
            removeHref(href.getAttribute('data-id'))
        })
    })
    hrefs.forEach(href => {
        href.addEventListener('click', (e) => {
            e.preventDefault()
            removeHref(href.getAttribute('data-id'))
        })
    })
}
const checkboxAjax = (check) => {
    $.ajax({
        type: 'post',
        dataType: 'json',
        url: wineFilter_globals.ajax_url,
        data: {
            id: check.getAttribute('value'),
            action: 'wineFilter_cart',
            _ajax_nonce: wineFilter_globals.nonce
        },
        success: function (response) {
            if (response.checked) {
                check.checked = true
            } else {
                check.checked = false
            }
            let popup = document.querySelector('.popup')
            let tbody = popup.querySelector('table tbody');
            tbody.innerHTML = response.cart
            popup.classList.add('active')
            addhrefs()
        }
    }
    )
}
const addcheckbox = () => {
    let checkboxes = document.querySelectorAll('.cardcheckbox');
    checkboxes.forEach(check => {
        if (check.hasAttribute('data-listener')) {
            check.removeEventListener('click', (e) => {
                e.preventDefault()
                checkboxAjax(check)
                check.removeAttribute('data-listener')
            })
        } else {
            check.addEventListener('click', (e) => {
                e.preventDefault()
                checkboxAjax(check)
                check.setAttribute('data-listener', 'true')
            })
        }
    })
}

if (document.querySelector('.b2b')) {
    addhrefs()
}

if (document.querySelector('.main .wines')) {
    addcheckbox()
    addhrefs()
    let selectors = [
        ['.filter__kind li a', -1],
        ['.filter__country li a', -1],
        ['.filter__taste li a', -1],
    ]

    selectors.forEach(sel => {
        let buttons = document.querySelectorAll(sel[0]);
        buttons.forEach(button => {
            if (button.parentElement.classList.contains('active')) {
                sel[1] = button.parentElement.getAttribute('number')
            }

            button.addEventListener('click', () => {
                if (button.parentElement.classList.contains('active')) {
                    button.parentElement.classList.remove('active');
                    sel[1] = -1
                } else {
                    buttons.forEach(b => {
                        if (b.parentElement.classList.contains('active')) {
                            b.parentElement.classList.remove('active')
                        }
                    })
                    button.parentElement.classList.add('active');
                    sel[1] = button.parentElement.getAttribute('number')
                }
                filter();
            })
        });
    });

    const clear = document.querySelector('#clear');
    clear.addEventListener('click', () => {
        selectors[0][1] = -1;
        selectors[1][1] = -1;
        selectors[2][1] = -1;
        selectors.forEach(sel => {
            let buttons = document.querySelectorAll(sel[0]);
            buttons.forEach(button => {
                if (button.parentElement.classList.contains('active')) {
                    button.parentElement.classList.remove('active')
                }
            });
        });
        if (filterSearch.value != null) {
            currentK = 0
            filterSearch.value = null;
        }
        filter();

    })

    let currentK = selectors[0][1];
    let currentC = selectors[1][1];
    let currentT = selectors[2][1];
    const filter = () => {
        if ((currentC != selectors[1][1]) || (currentK != selectors[0][1]) || (currentT != selectors[2][1])) {
            currentK = selectors[0][1];
            currentC = selectors[1][1];
            currentT = selectors[2][1];
            filterSearch.value = null;

            $.ajax({
                type: 'post',
                dataType: 'json',
                url: wineFilter_globals.ajax_url,
                data: {
                    kind: selectors[0][1],
                    country: selectors[1][1],
                    taste: selectors[2][1],
                    action: 'wineFilter_change',
                    _ajax_nonce: wineFilter_globals.nonce
                },
                success: function (response) {
                    if ('success' == response.type) {
                        if (response.data[4]) {
                            document.querySelector('.main .wines__description .glass').setAttribute('src', response.data[5])
                        }
                        if (response.data[3]) {
                            document.querySelector('.main .wines__description .landscape').setAttribute('src', response.data[3])
                        }
                        if (response.data[2]) {
                            document.querySelector('.main .wines__description .country').setAttribute('src', response.data[2])
                        }
                        if (response.data[0] != null) {
                            document.querySelector('.main .wines__description .wines__description__details h2').innerHTML = response.data[0]
                        }
                        if (response.data[1] != null) {
                            document.querySelector('.main .wines__description .wines__description__details p').innerHTML = response.data[1]
                        }
                        const box = document.querySelector('.main .wines__list')
                        const posts = response.data[5];
                        if (posts == '') {
                            box.innerHTML = '<h2> Nie znaleziono takich win </h2>'
                            loadMore.style.display = 'none'

                        } else {
                            let wines = '';
                            if (response.b2b) {
                                posts.forEach(post => {
                                    var checked = ''
                                    if (post['checked']) {
                                        checked = 'checked'
                                    }
                                    wines = wines + ' <div class="wine" data-aos="fade-up">  <div class="wine__details"> <img src="' + post['flag'] + '" alt="" class="wine__details__flag"> <h4> ' + post['countryName'] + '</h4> <span> <label for="wine' + post['id'] + '">zainteresowany</label> <input type="checkbox" value="' + post['id'] + '"' + checked + ' data-listener="true" class="cardcheckbox" id="wine' + post['id'] + '"> </span> </div> <img src="' + post['img'] + '" alt="" class="wine__image"> <a href="' + post['permalink'] + '"> <h3 class="wine__title">' + post['title'] + '</h3></a>  <div class="wine__description"> <p>' + post['excerpt'] + '</p> <a href="' + post['permalink'] + '" class="indicator">Czytaj więcej</a> </div> <div class="wine__price">' + post['price'] + '     </div>     </div>'
                                })
                            } else {
                                posts.forEach(post => {
                                    wines = wines + ' <div class="wine" data-aos="fade-up">  <div class="wine__details"> <img src="' + post['flag'] + '" alt="" class="wine__details__flag"> <h4> ' + post['countryName'] + '</h4> <span> </span> </div> <img src="' + post['img'] + '" alt="" class="wine__image"> <a href="' + post['permalink'] + '"> <h3 class="wine__title">' + post['title'] + '</h3></a>  <div class="wine__description"> <p>' + post['excerpt'] + '</p> <a href="' + post['permalink'] + '" class="indicator">Czytaj więcej</a> </div>      </div>'
                                })
                            }
                            box.innerHTML = wines;
                            if (response.b2b) {
                                addcheckbox()
                            }

                            if (response.max == paged.value) {
                                loadMore.style.display = 'none'
                            } else {
                                loadMore.style.display = 'none'
                            }
                        }
                    } else {
                        console.log('error');
                    }
                }
            })
        }
    }
    const filterSearch = document.querySelector('.main .filter .search-field');
    const submitBtn = document.querySelector('.main .filter .search-submit')

    submitBtn.addEventListener('click', (e) => {
        e.preventDefault();
        if (filterSearch.value != '') {
            $.ajax({
                type: 'post',
                dataType: 'json',
                url: wineFilter_globals.ajax_url,
                data: {
                    value: filterSearch.value,
                    action: 'wineFilter_name',
                    _ajax_nonce: wineFilter_globals.nonce
                },
                success: function (response) {
                    if ('success' == response.type) {
                        const posts = response.data[0];
                        const box = document.querySelector('.main .wines__list')
                        if (posts == '') {
                            box.innerHTML = '<h2> Nie znaleziono takich win </h2>'
                            loadMore.style.display = 'none'
                            selectors.forEach(sel => {
                                let buttons = document.querySelectorAll(sel[0]);
                                buttons.forEach(button => {
                                    if (button.parentElement.classList.contains('active')) {
                                        button.parentElement.classList.remove('active')
                                    }
                                });
                            });
                            selectors[0][1] = -1;
                            selectors[1][1] = -1;
                            selectors[2][1] = -1;
                        } else {
                            selectors.forEach(sel => {
                                let buttons = document.querySelectorAll(sel[0]);
                                buttons.forEach(button => {
                                    if (button.parentElement.classList.contains('active')) {
                                        button.parentElement.classList.remove('active')
                                    }
                                });
                            });
                            selectors[0][1] = -1;
                            selectors[1][1] = -1;
                            selectors[2][1] = -1;
                            let wines = '';
                            if (response.b2b) {
                                posts.forEach(post => {
                                    var checked = ''
                                    if (post['checked']) {
                                        checked = 'checked'
                                    }
                                    wines = wines + ' <div class="wine" data-aos="fade-up">  <div class="wine__details"> <img src="' + post['flag'] + '" alt="" class="wine__details__flag"> <h4> ' + post['countryName'] + '</h4> <span> <label for="wine' + post['id'] + '">B2B</label> <input type="checkbox" value="' + post['id'] + '"' + checked + ' data-listener="true" class="cardcheckbox" id="wine' + post['id'] + '" name="AGEDA DE MOLEIRO RED"> </span> </div> <img src="' + post['img'] + '" alt="" class="wine__image"> <a href="' + post['permalink'] + '"> <h3 class="wine__title">' + post['title'] + '</h3></a>  <div class="wine__description"> <p>' + post['excerpt'] + '</p> <a href="' + post['permalink'] + '" class="indicator">Czytaj więcej</a> </div> <div class="wine__price">' + post['price'] + '     </div></div>'
                                })
                            } else {
                                posts.forEach(post => {
                                    wines = wines + ' <div class="wine" data-aos="fade-up">  <div class="wine__details"> <img src="' + post['flag'] + '" alt="" class="wine__details__flag"> <h4> ' + post['countryName'] + '</h4> <span> </span> </div> <img src="' + post['img'] + '" alt="" class="wine__image"> <a href="' + post['permalink'] + '"> <h3 class="wine__title">' + post['title'] + '</h3></a>  <div class="wine__description"> <p>' + post['excerpt'] + '</p> <a href="' + post['permalink'] + '" class="indicator">Czytaj więcej</a> </div>      </div>'
                                })
                            }

                            box.innerHTML = wines;
                            if (response.b2b) {
                                addcheckbox()
                            }

                            if (response.max == paged.value) {
                                loadMore.style.display = 'none'
                            } else {
                                loadMore.style.display = 'none'
                            }
                        }
                    } else {
                        console.log('error');
                    }
                }
            })

        }
    })

    const loadMore = document.querySelector('#loadMore');

    loadMore.addEventListener('click', () => {
        $.ajax({
            type: 'post',
            dataType: 'json',
            url: wineFilter_globals.ajax_url,
            data: {
                kind: selectors[0][1],
                country: selectors[1][1],
                taste: selectors[2][1],
                paged: paged.value,
                search: filterSearch.value,
                action: 'wineFilter_load',
                _ajax_nonce: wineFilter_globals.nonce
            },
            success: function (response) {
                if ('success' == response.type) {
                    const posts = response.data[0];
                    const box = document.querySelector('.main .wines__list')

                    if (posts == '') {
                        box.innerHTML = '<h2> Nie znaleziono takich pozycji </h2>'
                    } else {
                        let wines = box.innerHTML;
                        document.querySelector('#paged').value = parseInt(paged.value) + 1
                        posts.forEach(post => {
                            wines = wines + ' <div class="wine" data-aos="fade-up">  <div class="wine__details"> <img src="' + post['flag'] + '" alt="" class="wine__details__flag"> <h4> ' + post['countryName'] + '</h4> <span> </span> </div> <img src="' + post['img'] + '" alt="" class="wine__image"> <a href="' + post['permalink'] + '"> <h3 class="wine__title">' + post['title'] + '</h3></a>  <div class="wine__description"> <p>' + post['excerpt'] + '</p> <a href="' + post['permalink'] + '" class="indicator">Czytaj więcej</a> </div>      </div>'
                        })
                        box.innerHTML = wines;
                        if (response.max == paged.value) {
                            loadMore.style.display = 'none'
                        } else {
                            loadMore.style.display = 'none'
                        }
                    }
                } else {
                    console.log('error');
                }
            }
        })
    })



}
