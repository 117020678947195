const menu = document.querySelector('.menu')
const menuBtn = document.querySelector('.hamburger');
const menuToggle = () => {
	menu.classList.toggle('open');
	menuBtn.classList.toggle('open');
}
document.addEventListener("DOMContentLoaded", () => {
	AOS.init({
		startEvent: 'load',
		offset: 50,
		duration: 1000,
		once: true
	})
	if (document.querySelector('.wpcf7')) {
		if (document.querySelector('#productName')) {
			let str = document.querySelector('#productName').innerHTML;
			document.querySelector('#product').setAttribute('value',str);
		}
	}
	menuBtn.addEventListener('click', () => {
		menuToggle()
	});
	
	const btn = document.querySelector('.scrollTop');

	window.onscroll = function () { scrollFunction() };

	function scrollFunction() {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			btn.style.display = "flex";
		} else {
			btn.style.display = "none";
		}
	}
	if(document.querySelector('.popup')){
		const popup = document.querySelector('.popup')
		const closePopup = document.querySelector('.popup .popup__close')
		closePopup.addEventListener('click',()=>{
			popup.classList.remove('active')
		})
	}
});
